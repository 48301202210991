import React, { type MouseEvent } from "react"
import { Page } from "../components/layout"
import type { CardProps } from "../components/card/Card"
import { Card } from "../components/card/Card"
import "./index.scss"
import { UniversalLink } from "../components/UniversalLink"
import { projectTypeDefinitions } from "../components/grantees/ChipData"
import { GranteeType } from "../components/grantees/grantee"
import { Chip } from "../components/grantees/Chip"
import { Timeline } from "../components/timeline/Timeline"
import { waves } from "../data/timelineData"
import grantees from "../data/granteeData"
import { GranteeCarousel } from "../components/grantees/GranteeCarousel"

const featuredProjectTypes: GranteeType[] = [
  GranteeType.DEFI,
  GranteeType.TOKENIZATION,
  GranteeType.INFRASTRUCTURE_SECURITY,
  GranteeType.TOOLING,
]
const featuredProjectTypeChipData = projectTypeDefinitions.filter(
  (value, _index, _array) => featuredProjectTypes.includes(value.value)
)

const StepCard: React.FC<CardProps & { description: string }> = ({
  description,
  title,
  trim,
}) => (
  <Card className="step-card" trim={trim}>
    <div className="caption-3">{title}</div>
    <p>{description}</p>
  </Card>
)

export const scrollToCTA = (event: MouseEvent<HTMLButtonElement>) => {
  const cta = document.getElementsByClassName(`cta`)[0]
  cta.scrollIntoView({ behavior: `smooth` })
  const input = cta.getElementsByTagName(`input`)[0]
  input.focus()
}

export const Index = () => (
  <Page
    header="Come Build on the XRP Ledger"
    title="Software Developer Grants Program"
    keywords="crypto grants, ripple funding, blockchain grants, web3 grants, xrpl grants, ripple fund, developer funding, developer grants, open source funding, ripple grants, blockchain funding, web3 funding, software developer grants, xrp ledger funding, xrpl funding, xrpl awards, open source developer grants"
    description="The XRPL Grants program funds software development projects that leverage the open-source XRP Ledger (XRPL). Apply for funding on your next project."
    slug="home"
  >
    <div className="grid grid-cols-2 sm:grid-cols-4 md:text-center md:grid-cols-8 lg:grid-cols-12">
      <h3 className="apply-sub-heading col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3">
        Applications are open and rolling <br />
      </h3>
    </div>
    <div className="subscribe-for-updates-btn">
      <UniversalLink
        className="btn btn-primary btn-auto"
        to="https://submit.xrplgrants.org/submit"
      >
        Apply Now
      </UniversalLink>
      {/* Applications are closed */}
      {/* <button className="btn btn-primary btn-auto" onClick={scrollToCTA}>
        Subscribe for Updates
      </button> */}
    </div>

    <div className="rolling-application">
      <h6 className="new-rolling-text">New!</h6>
      <div className="title">
        <h2>Rolling Application </h2>
        <h2>Process & Response</h2>
      </div>
      <p className="rolling-description">
        XRPL Grants is now accepting applications on a rolling basis. Letters of
        interest will be reviewed as they are received and applicants will be
        notified at each stage of the review process.
      </p>
      <p className="rolling-description">
        Our timeline for responses is an estimated approximation for each stage,
        with the XRPL Grants Team being continuously engaged with applicants
        throughout the entire process.
      </p>
    </div>
    <Timeline waves={waves} />
    <p className="rolling-notes mb-6">
      Please note: Each phase typically takes 20-30 business days, with ongoing
      efforts to complete each step as quickly and efficiently as possible.
    </p>
    <div className="awarding-process">
      <div className="title">
        <h2>Awarding Process</h2>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-y-[48px]">
        <StepCard
          title="STEP 1"
          description="Submit an Interest Form"
          trim="purple"
        />

        <StepCard
          title="STEP 2"
          description="Qualified projects will be invited to submit a full application"
          trim="yellow"
        />

        <StepCard
          title="STEP 3"
          description="Finalists are invited to interview"
          trim="blue"
        />

        <StepCard
          title="STEP 4"
          description="Awardees are selected"
          trim="orange"
        />

        <StepCard
          title="STEP 5"
          description="Awarded projects are onboarded and the first milestones are funded"
          trim="magenta"
        />
      </div>
      <section className="applying-xrpl-grants grid grid-cols-2 sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
        <h2 className="col-span-2 sm:col-span-3 lg:col-span-5">
          Applying to XRPL Grants
        </h2>
        <p className="text col-span-2 sm:col-span-4 md:col-start-5 lg:col-span-6 lg:col-start-7">
          XRPL Grants funds software developers interested in building technical
          standards and solutions to help grow the XRP Ledger Community,
          leveraging the XRPL’s open source technology.
        </p>
        <p className="project-types-text col-span-2 sm:col-span-4 md:col-start-5 lg:col-span-6 lg:col-start-7">
          Types of projects that may be funded include, but not limited to:
        </p>
        <div className="project-type-chips col-span-2 sm:col-span-4 md:col-start-5 lg:col-span-6 lg:col-start-7">
          {featuredProjectTypeChipData.map((data) => (
            <UniversalLink
              key={data.color + ` ` + data.name}
              to={`grantees/?type=${data.value}`}
              isInternal
            >
              <Chip color={data.color} readonly={true}>
                {data.name}
              </Chip>
            </UniversalLink>
          ))}
        </div>
        <div className="view-all-grantees-btn col-span-2 sm:col-span-4 md:col-start-5 lg:col-start-7">
          <UniversalLink
            className="btn btn-primary btn-auto"
            to="grantees"
            isInternal
          >
            View All Grantees
          </UniversalLink>
        </div>
      </section>
      <section className="featured-grantees-carousel">
        <GranteeCarousel grantees={grantees} />
      </section>
    </div>
  </Page>
)

export default Index
